import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
      event: String,
      properties: Object
    }

    track() {
      gtag('event', this.eventValue, this.propertiesValue);
      if (typeof amplitude !== 'undefined') {
        amplitude?.track(this.eventValue, this.propertiesValue);
      }
    }
}
