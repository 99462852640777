
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import Collapse from 'bootstrap/js/dist/collapse';

import '../scss/main.scss';


window.Stimulus = Application.start()
Stimulus.load(definitionsFromContext(require.context("../../../analytics/static/js/controllers", true, /\.js$/)))

